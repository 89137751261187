<template>
  <div class="bgcontent">
    <Header style="background: none">
      <template slot="title">转换器记录</template>
    </Header>
    <div class="list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            class="card"
            v-for="item in list"
            :key="item.id"
            :title="item.package_zh_name"
          >
            <div class="top">
              <div class="left">
                <img class="iconimg" :src="getImg(item.package_zh_name)" />
                <div>
                  <div class="title">{{ item.package_zh_name }}资源转换器</div>
                  <div class="everyday"></div>
                  <div class="txt">锁仓时间 {{ parseInt(item.ya_day) }} 天</div>
                </div>
              </div>
              <div class="right">
                <div class="btn">
                  {{ getStatusTxt(item.status) }}
                </div>
                <!--<div class="txt">收益率:{{toSecond(parseFloat(item.profit)*100)}}%</div>-->
                <div class="txt">已释放 {{ parseInt(item.day) }} 天</div>
              </div>
            </div>
            <div class="middle">
              <div class="txt">
                总持有 {{ parseFloat(item.profit).toFixed(3) }}
              </div>
              <div class="txt">
                已释放 {{ parseFloat(item.release_profit).toFixed(3) }}
              </div>
            </div>
            <div class="end">
              <div class="txt">
                <div>锁仓</div>
                <div>
                  {{ item.coin_symbol }} {{ parseFloat(item.price).toFixed(3) }}
                </div>
              </div>
              <div class="right">
                <div class="txt">
                  <div>创建时间</div>
                  <div>
                    {{ item.ctime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import { resourceLogList } from "@/request/api";
import { Toast } from "vant";
export default {
  name: "Environmentrecord",
  components: {
    Header,
  },
  data() {
    return {
      list: [],
      refreshing: false,
      loading: false,
      finished: false,
      address: sessionStorage.getItem("address"),
      currentPage: 1,
    };
  },
  mounted() {},
  created() {},
  activated() {
    this.list = [];
    this.onRefresh()
  },
  methods: {
    getImg(type) {
      let imgurl = "";
        if (type === "体验型") {
            imgurl = require('@/assets/images/index/micro.png')
        }
        else if (type === "微型") {
            imgurl = require('@/assets/images/index/super.png')
        }
        else if (type === "起点型") {
            imgurl = require('@/assets/images/index/small.png')
        } else if (type === '中型') {
            imgurl = require('@/assets/images/index/middle.png')
        } else if (type === '大型') {
            imgurl = require('@/assets/images/index/big.png')
        } else if (type === '巨型') {
            imgurl = require('@/assets/images/index/huge.png')
        } else if (type === '超级') {
            imgurl = require('@/assets/images/index/chaoji.png')
        } else if (type === '云级') {
            imgurl = require('@/assets/images/index/a_2.png')
        } else if (type === '小型') {
            imgurl = require('@/assets/images/index/a_1.png')
        }
      return imgurl;
    },
    getStatusTxt(status) {
      // 状态0链上确认中，1质押中，2已赎回 3已到期
      let txt = "";
      switch (status) {
        case 0:
          txt = "确认中";
          break;
        case 1:
          txt = "质押中";
          break;
        case 2:
          txt = "已赎回";
          break;
        case 3:
          txt = "已到期";
          break;
      }
      return txt;
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.currentPage = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      if (this.address && sessionStorage.getItem(this.address)) {
        this.getDataList();
        this.currentPage++;
      } else {
        this.loading = false;
      }
    },
    getDataList() {
      resourceLogList({
        address: this.address,
        limit: "20",
        page: this.currentPage,
      }).then((res) => {
        if (this.refreshing) {
          this.refreshing = false;
          this.list = [];
        }
        this.list = [...this.list, ...res.data.list];
        this.loading = false;
        if (res.data.list.length < 20) {
          this.finished = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bgcontent {
  min-height: 100vh;
  padding: 10px 32px;
  background: url("../../assets/images/transfer_bg.png");
  background-size: 100% 100%;
  .header {
    display: flex;
    justify-content: center;
    .back {
      position: relative;
      left: -220px;
      .backimg {
        width: 48px;
        height: 48px;
      }
    }

    .title {
      opacity: 1;
      color: rgba(13, 25, 18, 1);
      font-size: 36px;
      font-weight: 700;
      font-family: "PingFang SC";
      text-align: center;
    }
  }
  .list {
    margin-top: 32px;
    .card {
      padding: 18px 24px;
      width: 670px;
      height: 398px;
      border-radius: 16px;
      opacity: 1;
      background: linear-gradient(
        180deg,
        rgba(238, 221, 154, 1) 0%,
        rgba(247, 237, 197, 1) 52%,
        rgba(228, 212, 147, 1) 100%
      );
      box-shadow: inset -4px -4px 8px 0 rgba(239, 219, 139, 1),
        -4px -4px 8px 0 rgba(167, 148, 91, 1);
      margin-bottom: 32px;
    }
    .top {
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;

        .iconimg {
          width: 156px;
          height: 156px;
        }
        .title {
          opacity: 1;
          color: rgba(53, 44, 11, 1);
          font-size: 36px;
          font-weight: 700;
          font-family: "PingFang SC";
          line-height: 48px;
        }
        .everyday {
          color: rgba(53, 44, 11, 1);
          font-size: 44px;
          font-weight: 500;
          font-family: "DIN";
          text-align: center;
        }
        .txt {
          margin-top: 32px;
        }
      }
      .txt {
        color: rgba(101, 84, 21, 1);
        font-size: 24px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: center;
      }
      .right {
        .btn {
          width: 108px;
          height: 44px;
          background-color: rgba(39, 199, 204, 0.2);
          border-radius: 8px 0px 0px 8px;
          font-weight: 400;
          font-size: 24px;
          line-height: 44px;
          text-align: center;
          color: #27c7cc;
        }
        .txt {
          margin-top: 36px;
        }
      }
    }
    .middle {
      margin-top: 20px;
      width: 622px;
      height: 56px;
      border-radius: 16px;
      background: rgba(53, 44, 11, 0.05);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      .txt {
        color: rgba(151, 125, 30, 1);
        font-size: 24px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: center;
      }
    }
    .end {
      margin-top: 46px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .right {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>